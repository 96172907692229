<template>
  <v-footer
    style="background-color: #7024c4; width: 100vw; height: 50px; color: white;"
    class="d-flex align-center px-5"
  >
    © 2022 Healthy Communities <span class="ml-5">All right reserved</span>
  </v-footer>
</template>
<script>
export default {};
</script>
